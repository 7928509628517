@import "normalize.css";
@import "bulma/css/bulma.css";

.weinbauhm {
	font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	color: #363636;
	a {
		color: #c5b23b;
		&:hover {
			color: #d8c02e;
		}
	}
	p {
		margin: 0 0 .5rem 0;
	}
	.section {
		position: relative;
		padding: 2.7rem 1.5rem;

		&.section-line {
			&:after {
				display: block;
		    position: absolute;
				content: '';
		    top: 0;
		    left: 35%;
		    right: 35%;
		    height: 3px;
		    background-color: #fdf3bb;
		    border-radius: 2px;
			}
		}
	}
	.icon {
		svg {
			display: block;
			width: 24px;
			height: 24px;
		}
	}
	.navbar {
		background-color: transparent;
		.navbar-item {
			background-color: #fff;
			&:hover {
				background-color: #fffbdf;
				span {
					color: #6f620f;
					//color: #d8c02e;
				}
				.icon {
					//fill: #ab950c;
					fill: #899e09;
					transform: scale(1.03);
				}
			}

			.icon {
				fill: #7eab0c;
				transform: scale(1);
				transition: transform .08s linear;
				& + span {
					padding-left: .3rem;
				}
			}
			span {
				color: #4c4307;
				font-weight: 400;
			}
		}
		.navbar-brand {
			.navbar-item {
				background-color: #d8c02e;
				padding: 0 1rem 0 1.3rem;
				transition: transform .08s linear;
				img {
					display: block;
					max-height: 1.9rem;
					transform: scale(1.08);
				}
				&:hover {
					background-color: #d8c02e;
					transform: scale(1.06);
				}
			}
		}
		.navbar-weinbauhm {
			display: flex;
			align-items: stretch;
			height: 3.25rem;
			width: 100%;
			background-color: #fff;
			margin: 0 0 0 0;
			box-shadow: 0 7px 5px 1px rgba(0,0,0,.1), 0 1px 4px 0px rgba(0,0,0,.3);
		}
	}
	.hero-weinbauhm {
		background-image: url(./assets/weinbau-hm_2.jpg);
		background-attachment: scroll;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: transparent;
		background-size: cover;
		line-height: 1.3;

		.hero-body {
			padding-top: 6rem;

			.pretitle {
				display: block;
				font-size: 1.2rem;
				text-transform: uppercase;
				font-weight: 400;
				letter-spacing: 0.06rem;
				margin-bottom: .1rem;
			}
			.title {
				display: block;
				font-size: 3.7rem;
				color: #ffe962;
				text-shadow: 0 2px 5px rgba(0, 0, 0, 0.23);
				line-height: 1.3;
			}
			.subtitle {
				display: block;
				font-size: 1rem;
			}
		}
	}
	.section-weinbauhm {
		.title {
			display: block;
			font-weight: 400;
			font-size: 1.3rem;
			padding-left: 1rem;
			line-height: 1.3;
			color: #739205;
			margin: 0;
			&:not(.is-spaced) + .subtitle {
				margin-top: -.3rem;
			}
		}
		.subtitle {
			display: block;
			font-weight: 500;
			color: #ccb218;
			font-size: 2.1rem;
			line-height: 1.3;
			margin: 0 0 1rem 0;
		}
	}
	.section-image {
		display: block;
		margin-top: -5rem;
		position: relative;
		z-index: 3;
		margin-bottom: .6rem;

		.section-image__text {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			color: #fff;
			padding: .6rem 1rem;
			font-size: 1.2rem;
		}
		img {
			display: block;
			border-radius: 2px;
			box-shadow: 0 -8px 17px 0px rgba(0, 0, 0, 0.13), 0 -1px 3px 0px rgba(0, 0, 0, 0.3);
		}
	}
	.section-title {
		margin: -1rem 0 2rem 0;
	}
	.weine {
		display: block;
		position: relative;
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		overflow-x: scroll;

		.weine-image {

		}
		.weine-text {
			display: block;

			.title {
				display: inline;
				padding: 0;
				color: rgba(255, 255, 255, 0.8);
				position: absolute;
				left: -13%;
				bottom: 36%;
				transform: rotateZ(90deg);
				font-size: 1.7rem;
			}
		}

		.weine-item {
			display: block;
			position: relative;
			margin: 0 auto;
			text-align: center;
			padding: 3rem 2rem;
			    box-shadow: -70px 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 0px 9px 2px rgba(0, 0, 0, 0.1), 0px 5px 23px 5px rgba(0, 0, 0, 0.3);

			img {
				display: inline-block;
				max-height: 23rem;
			}
			&.weine-item--wein1 {
				background-color: #88944b;
			}
			&.weine-item--wein2 {
				background-color: #90bd82;
			}
			&.weine-item--wein3 {
				background-color: #f1ee7e;
			}
			&.weine-item--wein4 {
				background-color: #d66d5b;
			}
			&.weine-item--wein5 {
				background-color: #d62c52;
			}
		}
	}
	.navbar-burger {
	 	span {
			width: 21px;
	    height: 2px;
			left: calc(50% - 10px);

			&:nth-child(1) {
		    top: calc(50% - 7px);
			}

			&:nth-child(2) {
		    top: calc(50% - 1px);
			}

			&:nth-child(3) {
		    top: calc(50% + 5px);
			}
		}
		&.is-active span:nth-child(1) {
			top: calc(50% - 5px);
		}
	}
	@media screen and (min-width: 1024px) {
		.navbar .navbar-weinbauhm {
			margin-top: 1rem;
		}
		.navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
		   margin-left: -.25rem;
		}
		.navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
	    margin-right: -.25rem;
		}
		.section-weinbauhm.has-text-centered {
			text-align: left !important
		}
		.navbar-mobile.navbar-menu {
			display: none;
		}
	}
	@media screen and (max-width: 620px) {
		.hero.is-fullheight {
			min-height: 73vh;
		}
		.hero-weinbauhm .hero-body .pretitle {
			font-size: 1rem;
		}
		.hero-weinbauhm .hero-body .title {
			font-size: 2.7rem;
		}
		.section {
			padding: 1.5rem 1rem;
		}
		.section-weinbauhm .title {
			padding-left: .3rem;
		}
		.section-weinbauhm .subtitle {
			font-size: 1.6rem;
		}
		.section-image {
			margin-top: -1rem;
		}
		.footer,
		.footer .column {
			text-align: center !important;
		}
		.navbar-menu.navbar-mobile {
			padding: 0;
		}
		.navbar-menu,
		.navbar-burger {
			&.is-active {
				background-color: #fffbdf;
				a {
					background-color: #fffbdf;
					&:hover {
						background-color: #fff;
					}
				}
			}
		}
	}
}
